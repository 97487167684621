import { useState } from "react";
import useToolsRenderSupport from "../../../tools/toolsRenderSupport";

export default function usePreview() {
    const {renderPreview} = useToolsRenderSupport();
    
    
    const showRaw = (row, mapping, selectedPreview) => {
        console.log("ENTRA1: " + selectedPreview)
        if(mapping !== undefined && selectedPreview !== undefined) {
            console.log("ENTRA2")
            return renderPreview(mapping, row, selectedPreview.item, false)
        }
        return ''
    }

    const showPreview = (row, mapping, selectedPreview) => {
        if(mapping !== undefined && selectedPreview !== undefined) {
            return renderPreview(mapping, row, selectedPreview.item, true)
        }
        return ''
    }

    return {
        showRaw,
        showPreview,
    };
}